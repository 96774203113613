
export const FILTER_CATEGORY = [
   {value: "HORSE", title: "Horse"},
   {value: "TRAINER", title: "Trainer"},
   {value: "OTHER_SERVICES", title: "Other Serivces"}

]
export const Filter = {
    TRAINER: "TRAINER",
    HORSE: "HORSE",
    OTHER_SERVICES: "OTHER_SERVICES"
}
export const Categories = {
    STABLE_HACKING_BOOKING: 'stable_hacking_booking',
    STABLE_RENT_BOOKING: 'stable_rent_booking',
    COMPETITION: 'competition',
    TRAINER_BOOKING_SLOT: 'trainer_bookings_slot',
    STABLE_SERVICE_BOOKINGS_SLOT: "stable_bookings_slot",
    STABLE_UNAVAILABLITIES: "stable_unAvailability",
    STABLE_SHIFT: "stables_shift",
    TRAINER_SHIFT: "trainers_shift",
    CLOSED_HOURS: "CLOSED_HOURS",
    BREAK: "BREAK",
    UNAVAILABLE: "UNAVAILABLE"
}
