import styled from "styled-components";
import { COLORS, FONTS, DrawerWidth, ClosedDrawerWidth } from "Styles/Constants";

export const SidebarWrapper = styled.div`
    .drawer {      
        
        .drawer-paper {
            /* background-color: ${COLORS.SECONDARY}; */
            background-color: #70e1aa;
            background-image: linear-gradient(0deg, #70e1aa 0%, #1f276f 80%);

            color: ${COLORS.WHITE};
            -moz-box-shadow:    -3px 0 15px 0 #555;
            -webkit-box-shadow: -3px 0 15px 0 #555;
            box-shadow:         -3px 0 15px 0 #555;
            .sidebar-logo-container {
                padding: 0px;
                .sidebar-logo {
                    padding: 24px 16px;
                    color: ${COLORS.WHITE}
                }
            }
            .sidebar-list-item-container {
                padding: 16px;
                padding-left: 24px;

                cursor: pointer;
                .sidebar-icons {
                    color: ${COLORS.WHITE}
                }
                .sidebar-list-item-text {
                    font-size: 16px;
                    font-weight: bold;
                    font-family: ${FONTS.PRIMARY}
                }
            }
            .sidebar-list-logout-item-container {
                position: absolute;
                bottom: 0;
            }
            .selected-list-item {
                border-top: solid 1px rgba(123, 200, 169, 0.3);
                border-bottom: solid 1px rgba(123, 200, 169, 0.3);
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        .drawer-paper-opened {
            width: ${DrawerWidth}px;
        }

        .drawer-paper-closed {
            width: ${ClosedDrawerWidth}px;
        }
        .sidebar-icons {
            color: ${COLORS.APP_TERTIRY}
        }   

    }

    .drawer-closed {
        width: ${ClosedDrawerWidth}px;
    }
    
    @media only screen and (max-width: 960px) {
        .drawer {
            width: ${DrawerWidth}px;
            flex-shrink: 0;
        }
    }
`;