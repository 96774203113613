// CORE
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { connect } from 'react-redux';

// USER DEFINED
import Routes from "Routes/Route";
import TopBar from "./Commons/Topbar/Topbar";
import EventManager from "./EventManager/EventManager";
import { ContentWrapper } from "./Website.style";
import Sidebar from "./Commons/Sidebar/Sidebar";
import Api from 'Helpers/ApiHandler';
import { APIS_END_POINTS } from 'Helpers/Paths';
import { saveLogo } from 'Redux/Auth/Actions';

class Website extends Component {

	componentDidMount() {
		const { isLoggedIn } = this.props;
		if (isLoggedIn) {
			new Api().get(APIS_END_POINTS.USER_PROFILE).then(response => {
				if (response) {
					localStorage.setItem("timezone_value", response.data.timezone_value)
					const logo = response.data?.stables[0]?.logo;
					if (logo) {
						this.props.saveLogo(logo);
					}
				}
			})
		}
	}
	render() {
		const { isLoggedIn } = this.props;
		return (
			<React.Fragment>
				{isLoggedIn && <TopBar />}
				{isLoggedIn && <Sidebar />}
				<ContentWrapper>
					<div className={`website-main-container full-height ${this.props.isLoggedIn && "website-container"} 
						${this.props.isLoggedIn && (this.props.drawerOpened ? "website-container-drawer-opened" : "website-container-drawer-closed")} 
						${this.props.isMobileView && "website-container-drawer-closed"}
						`}
					>
						<div className="full-height" style={{ overflow: "auto" }} >
							<Routes />
						</div>

					</div>
				</ContentWrapper>

				<EventManager />
			</React.Fragment>
		);
	}
}


const mapReduxStateToProps = (state) => ({
	isLoggedIn: state.Auth.isLoggedIn,
	drawerOpened: state.General.drawerOpened,
	isMobileView: state.General.isMobileView
});

const mapDispatchStateToProps = dispatch => ({
	saveLogo: (payload) => dispatch(saveLogo(payload)),
});

export default connect(mapReduxStateToProps, mapDispatchStateToProps)(Website);

// export default Website;