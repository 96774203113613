import { Form } from "formik";
import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";

export const InputWrapper = styled.div`
    position : relative;
    text-align  : left;
    margin-bottom       : 25px;
    margin-bottom       : 16px;                
    .label {
        color                   : ${COLORS.SECONDARY};
        font-family             : ${FONTS.PRIMARY_BOLD};
        font-size               : 13px;
        
        text-transform  : uppercase;
        
    }
    .error {
        color   : ${COLORS.DANGER};
        font-size   : 16px;
    }   
`


export const FormWrapper = styled(Form)`
	margin	: 0 auto;
	width	: 100%;
	
	 .profile-text {
          	margin-bottom		: 15px;
        }
	
	.image-wrapper {
		border 	: 1px solid ${COLORS.SECONDARY};
		border-radius : 50%;
		cursor : pointer;
		margin 	: 5px auto 15px;
		height 	: 105px;
		width 	: 105px;
		background-size : cover;
		background-position : center;

		${responsive.DISPLAY`
			border-width : 3px;
			height	: 80px;
			width	: 80px;
		`}
	}

	.input-wrapper {
		.input-label-wrapper {
			margin : 5px 0;

			${responsive.DISPLAY`margin: 0;`}
		}
	}

	.button-wrapper {
		margin-top : 45px;

		.styled {
			width : 200px;
			margin : 0 20px;

			${responsive.DISPLAY`width: 175px;`}
		}
	}
	
	 .label {
        color                   : ${COLORS.SECONDARY};
        font-family             : ${FONTS.PRIMARY_BOLD};
        font-size               : 13px;
        
        text-transform  : uppercase;
        // letter-spacing  : 1px;

        /*${responsive.DISPLAY`font-size : 11px;`}*/
    }
`

export const MainWrapper = styled.div`
    height              : 100%;
    display             : flex;
    justify-content     : center;
    align-items         : center;

    .btn {
        color               : ${COLORS.WHITE};
        font-family         : ${FONTS.PRIMARY_MEDIUM};
        font-size           : 18px;
        font-weight         : 600;
        text-transform      : capitalize;
        border-radius       : 2em;
        /* background-color   : ${COLORS.SECONDARY}; */
        background-color: #70e1aa;
        background-image: linear-gradient(90deg, #70e1aa 0%, #1f276f 100%);


        :hover {
            background-color   : ${COLORS.SECONDARY};

            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            box-sizing: border-box;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }

    .text-field {
        color               : ${COLORS.SECONDARY};
        margin-bottom       : 25px;
        font-family         : ${COLORS.PRIMARY_MEDIUM};
        font-size           : 18px;
    }

    .MuiFormLabel-root {
        color               : ${COLORS.SECONDARY};
        font-family         : ${FONTS.PRIMARY_BOLD};
        font-size           : 18px;
    }

    .MuiInputBase-input {
        color               : ${COLORS.SECONDARY};
        font-family         : ${FONTS.PRIMARY_MEDIUM};
        font-size           : 19px;
    }

    .MuiInput-underline:before, .MuiInput-underline:after {
        border-bottom        : 2px solid ${COLORS.SECONDARY}
    }
`
export const LoginWrapper = styled.div`
    width           : 448px;
    color           : ${COLORS.SECONDARY};

    .header {
        font-family         : ${FONTS.PRIMARY_BOLD};
        flex-direction      : column;

        img {
            margin      : auto;
            margin-top  : 40px;
            width       : 180px;
            height      : 180px;
        }

        .title {
            margin          : 40px auto;
            font-size       : 30px;
        }
    }

    .footer {
        flex-direction      : column;
        text-align          : center;

        a {
            color                   : ${COLORS.SECONDARY};
            text-decoration         : none;
            font-size               : 15px;
            font-family             : ${FONTS.PRIMARY_MEDIUM};

            :hover {
                text-decoration         : underline;
            }
        }

        .f-password {
            margin          : 13px auto;
        }

        .login-btn {
            width           : 264px;
            height          : 40px;
            margin          : 12px auto;
        }

        .sign-up {
            margin          : 12px auto;
        }

        .facebook {
            margin              : 70px auto 25px;

            .facebook-text {
                margin-right        : 10px;
                font-size           : 15px;
                font-family         : ${FONTS.PRIMARY_MEDIUM};
            }

            .icon {
                background-color        : #385C8E;
                width                   : 40px;
                height                  : 40px;
                margin                  : auto;
                border-radius           : 50%;
            }
        }
    }
`