import styled from "styled-components";
import { ClosedDrawerWidth, COLORS, DrawerWidth, TopbarHeight } from "Styles/Constants";

export const ContentWrapper = styled.div`
    background-color    : ${COLORS.WHITE};
    height: 100%; 
    min-height : 100vh;
    position: relative;
    // padding             : 15px;
    flex-grow: 1;

    .website-main-container {
        position: fixed;
        width: 100%;
    }
    
    .website-container {
        padding-top: ${TopbarHeight}px;
        width: 100%;
        position: fixed;
    }
    .website-container-drawer-opened {
        margin-left: ${DrawerWidth}px;
        width: calc(100% - ${DrawerWidth}px);
    }
    .website-container-drawer-closed {
        margin-left: ${ClosedDrawerWidth}px;
        width: calc(100% - ${ClosedDrawerWidth}px);

    }
`