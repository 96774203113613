import React from "react";
import { SidebarWrapper } from "./Sidebar.style";
import { Drawer, Button, List, ListItem, ListItemText, ListItemIcon, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SidebarItems } from "./SidebarItem";
import { URL_DASHBOARD } from "Helpers/Paths"
import { logOutUser } from 'Redux/Auth/Actions';
import { openDrawer, closeDrawer } from 'Redux/General/Actions';


class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }
    navigateTo = (url = URL_DASHBOARD) => {

        this.props.history.push({ pathname: url })
    }

    listItemSelectHandler = (selectedItem, selectedIndex) => {
        this.setState({
            selected: selectedIndex
        });
        this.navigateTo(selectedItem.path);
        if (this.props.isMobileView && this.props.drawerOpened) {
            this.handleDrawerToggle();
        }
    }
    handleDrawerToggle = () => {
        if (this.props.drawerOpened) {
            this.props.closeDrawer();
        }
        else {
            this.props.openDrawer();
        }
    }
    sidebarLogoClickHandler = () => {
        this.handleDrawerToggle();
    }

    logoutClickHandler = () => {
        this.props.logOutUser();

    }

    render() {
        const drawer = (
            <div className="full-height">
                <div className="flex f-v-center f-h-center f-column">
                    <Button disableFocusRipple
                        component="div"
                        mb={2}
                        fullWidth
                        classes={{ root: "sidebar-logo-container" }}
                        onClick={this.sidebarLogoClickHandler}
                    >
                        {this.props.drawerOpened ? <div className="sidebar-logo">Equester</div>
                            : <div className="sidebar-logo"><img src={require(`../../../Assets/Images/mainLogo.png`)} alt="logo" width="40px" height="40px" /></div>
                        }

                    </Button>
                </div>
                <List>
                    {SidebarItems.map((item, index) => (
                        <ListItem key={index} className={`sidebar-list-item-container ${((item.path === this.props.location.pathname) && "selected-list-item")}`}
                            onClick={() => this.listItemSelectHandler(item, index)}
                            selected={item.path === this.props.location.pathname}>
                            <ListItemIcon className="sidebar-icons">
                                <img src={require(`../../../Assets/Images/${item.icon}.png`)} alt={item.title} width="30px" height="30px" />
                            </ListItemIcon>
                            {this.props.drawerOpened && <ListItemText primary={<Typography variant="body2" className="sidebar-list-item-text">{item.title}</Typography>} />}
                        </ListItem>


                    ))}
                    <ListItem button className={`sidebar-list-item-container `}
                        onClick={() => this.logoutClickHandler()}>
                        <ListItemIcon className="sidebar-icons">
                            <img src={require(`../../../Assets/Images/logout.png`)} alt="logout" width="30px" height="30px" />
                        </ListItemIcon>
                        {this.props.drawerOpened && <ListItemText primary={<Typography variant="body2" className="sidebar-list-item-text">Logout</Typography>} />}
                    </ListItem>
                </List>

            </div>
        );

        return (
            <SidebarWrapper>
                <nav className={`drawer ${!this.props.drawerOpened && "drawer-closed"}`} aria-label="mailbox folders">
                    <Drawer
                        anchor="left"
                        open={true}
                        classes={{
                            paper: `drawer-paper ${this.props.drawerOpened ? "drawer-paper-opened" : "drawer-paper-closed"}`
                        }}
                        variant={"permanent"}

                    >
                        {drawer}
                    </Drawer>
                </nav>
            </SidebarWrapper >
        )
    }
}
const mapReduxStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
    user: state.Auth.user,
    drawerOpened: state.General.drawerOpened,
    isMobileView: state.General.isMobileView
});

const mapDispatchToProps = (dispatch) => ({
    openDrawer: (payload) => dispatch(openDrawer(payload)),
    closeDrawer: (payload) => dispatch(closeDrawer(payload)),
    logOutUser: (payload) => dispatch(logOutUser(payload)),

})

export default connect(mapReduxStateToProps, mapDispatchToProps)(withRouter(Sidebar));