export const ACTION_TYPES = {
    TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
    TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE",
    DRAWER_OPEN: "DRAWER_OPEN",
    DRAWER_CLOSE: "DRAWER_CLOSE",
    MOBILE_VIEW: "MOBILE_VIEW",
}

export const showToast = (message) => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
        message: message || ''
    }
}
export const hideToast = () => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_DISABLE
    }
}

export const openDrawer = () => {
    return {
        type: ACTION_TYPES.DRAWER_OPEN
    }
}

export const closeDrawer = () => {
    return {
        type: ACTION_TYPES.DRAWER_CLOSE
    }
}

export const mobileViewChange = (payload) => {
    return {
        type: ACTION_TYPES.MOBILE_VIEW,
        payload: payload
    }
}