import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { APIS_END_POINTS, URL_DASHBOARD} from "Helpers/Paths";
import { loginUser, saveLogo, saveUser } from "Redux/Auth/Actions";
import Api from "Helpers/ApiHandler";
import { LoginWrapper } from "./Login.style";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import HorseIcon from "Assets/Images/logo.png";
import { InputWithLabel } from "Components/Commons/Common";
import { FormWrapper, MainWrapper } from "Components/Commons/Common.style";
import { generateValidationSchema } from "Helpers/Utils";


const inputs = [
    {
        label: "Email",
        name: "email",
        validation: { required: true, email: true }
    },
    {
        label: "Password",
        name: "password",
        type: "password",
        validation: { required: true, min: 6 }
    }
]

const initialValues = {
    email: "",
    password: ""
};

const validationSchema = generateValidationSchema(inputs)

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roleList: [],
            selectedRole: null
        }
    }
    componentDidMount() {
        this.checkIfAlreadyLoggedIn();
        this.getRoleList();
    }

    checkIfAlreadyLoggedIn() {
        if (this.props.isLoggedIn)
            this.props.history.push(URL_DASHBOARD)
    }

    getRoleList = async () => {
        await new Api().get(APIS_END_POINTS.USER_ROLES).then(response => {
            if (response && response.data) {

                const stable = response.data.find(role => role.roleName === "Stable")
                // this.setState({
                //     roleList: response.data,
                // });
                if (stable) {
                    this.setState({
                        selectedRole: stable.id
                    })
                }
            }
        });

      
    }

    handleLogIn = (values) => {
        const data = {
            "email": values.email,
            "password": values.password,
            "roleId": this.state.selectedRole,
            "deviceType": "web",
            "deviceToken": "web"
        }

        new Api().post(APIS_END_POINTS.USER_SIGN_IN, { data })
            .then(response => {
                localStorage.setItem("accessToken", response.data.token)
                this.props.saveUser(response.data);
                if (response.data && response.data.stableDetails && response.data.stableDetails.logo) {
                    this.props.saveLogo(response.data.stableDetails.logo);
                }
                this.props.loginUser()
                this.props.history.push(URL_DASHBOARD)
            })
            .catch(error => {

            });
    }

    render() {
        return (
            <MainWrapper>
                <LoginWrapper>
                    <div className="header flex">
                        <img
                            src={HorseIcon}
                            alt="Bird Logo"
                        />
                        <div className="title"> Sign in </div>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleLogIn}
                        validationSchema={validationSchema}
                    >
                        {({ values, touched, errors, handleChange }) =>
                            <FormWrapper>
                                {
                                    inputs.map((input, index) =>
                                        <InputWithLabel
                                            key={index}
                                            className="full-width"
                                            name={input.name}
                                            placeholder={input.label}
                                            label={input.label}
                                            type={input.type}
                                            value={values[input.name]}
                                            onChange={handleChange}
                                            validated={touched[input.name] && errors[input.name]}
                                        />
                                    )
                                }

                                <div className="footer flex">
                                    <div className="login-btn">
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            className="btn"
                                            fullWidth
                                        >
                                            Log In
                                        </Button>
                                    </div>

                                    {/* <div className="sign-up">
                                        <Link to={URL_SIGNUP}>
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </div> */}
                                </div>

                            </FormWrapper>
                        }
                    </Formik>
                </LoginWrapper>
            </MainWrapper>
        )
    }
}

const mapReduxStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
});

const mapDispatchStateToProps = dispatch => ({
    saveUser: (payload) => dispatch(saveUser(payload)),
    saveLogo: (payload) => dispatch(saveLogo(payload)),
    loginUser: (payload) => dispatch(loginUser(payload)),
});

export default connect(mapReduxStateToProps, mapDispatchStateToProps)(withRouter(Login));
