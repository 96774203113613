import styled from "styled-components";
import Toolbar from '@material-ui/core/Toolbar';
import { ClosedDrawerWidth, COLORS, DrawerWidth, FONTS } from "Styles/Constants";
export const TopbarWrapper = styled.div`    
    .app-bar {
        background-color: ${COLORS.SECONDARY};
        /* background-image: linear-gradient(270deg, #70e1aa 0%, #1f276f 80%); */

        color: ${COLORS.APP_PRIMARY};
        /* box-shadow:0 0px 5px 0px ${COLORS.GREY}; */
        height: 64px;

    }
    .app-bar-drawer-opened {
        width: calc(100% - ${DrawerWidth}px);
        margin-left: ${DrawerWidth}px;
    }

    .app-bar-drawer-closed {
        width: calc(100% - ${ClosedDrawerWidth}px);
        margin-left: ${ClosedDrawerWidth}px;
    }

`;
export const ToolbarWrapper = styled(Toolbar)`
    justify-content: space-between;
    box-shadow: none;
    .menu-button {
    }

    .topbar-header {
        font-weight: bold; 
        font-family: ${FONTS.PRIMARY} 
    }

    
`
export const LinksWrapper = styled.div`
    .topbar-notification-badge {
        background-color: ${COLORS.APP_TERTIRY};
        color: ${COLORS.APP_PRIMARY};
        font-size: 10px;
        font-weight: bold;
    }
`