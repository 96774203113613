import * as Yup from "yup";
import { Messages } from "./Messages";
import moment from "moment";

export const isEqualsArray = (arr1, arr2) => {
  if (!(arr1 instanceof Array) || !(arr1 instanceof Array)) return false;

  if (arr1.length !== arr2.length) return false;

  if (arr1 instanceof Object && arr2 instanceof Object) {
    let isEqual = true,
      arrayLength = arr1.length;
    for (let index = 0; index < arrayLength && isEqual; index++) {
      if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index]))
        isEqual = false;
    }

    return isEqual;
  }

  return false;
};

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isIEBrowser() {
  // BROWSER CHECK VARIABLES
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  // const msedge = ua.indexOf('Edge/');
  return msie > 0 || msie11 > 0;
  // const isEdge = msedge > 0;
}

export function generateValidationSchema(inputs) {
  let validationObject = {};

  inputs.forEach((input) => {
    let { validation, name } = input;
    if (input.validation) {
      let yup = validation.number ? Yup.number() : Yup.string();

      /* GENERAL */

      if (validation.required)
        yup = yup.required(Messages.validation[name].required);

      if (validation.equals)
        yup = yup.oneOf(
          [Yup.ref(validation.equals), null],
          Messages.validation[name].equals
        );

      /* NUMBER TYPE */
      if (validation.number)
        yup = yup.typeError(Messages.validation[name].number);

      if (validation.min)
        yup = yup.min(validation.min, Messages.validation[name].min);

      if (validation.positive)
        yup = yup.positive(Messages.validation[name].positive);

      if (validation.integer)
        yup = yup.integer(Messages.validation[name].integer);

      /* SPECIFIC */
      if (validation.email) yup = yup.email(Messages.validation[name].valid);

      // if (validation.phoneNumber)
      //     yup = yup.matches(phoneRegExp, Messages.validation[name].valid)

      validationObject[name] = yup;
    }
  });

  return Yup.object(validationObject);
}

export function time_converter(date, offset) {
  if (offset) {
    let newDateFormat = moment(date).utcOffset(offset);
    return new Date(moment(newDateFormat._d).format("YYYY-MM-DD hh:mm:ss A"))
  }
}
