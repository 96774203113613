import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { ToolbarWrapper, LinksWrapper, TopbarWrapper } from "./Topbar.style";
import { connect } from 'react-redux';
import { closeDrawer, mobileViewChange, openDrawer } from 'Redux/General/Actions';
import { logOutUser } from 'Redux/Auth/Actions';
import RoutesList from "../../../Routes/RoutesList";


function Topbar(props) {
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 960 && !props.isMobileView) {
                props.closeDrawer();
                props.mobileViewChange(true);
            }
            else if (window.innerWidth >= 960 && props.isMobileView) {
                props.openDrawer()
                props.mobileViewChange(false);
            }

        }

        window.addEventListener('resize', handleResize)
        handleResize();


        return () => window.removeEventListener('resize', handleResize);



    });

    // function navigateTo(url) {
    //     props.history.push(url)
    // }
    const currentRoute = RoutesList.filter((route) => route.path === props.location.pathname);
    return (
        // <AppBar position="static">
        //     <ToolbarWrapper>
        //         <Typography variant="h6" >
        //             OpenXcell
        //         </Typography>

        //         <LinksWrapper>
        //             <Button color="inherit" onClick={() => navigateTo(URL_DASHBOARD)}>Dashboard</Button>
        //             <Button color="inherit" onClick={() => navigateTo(URL_USER)}>User</Button>
        //         </LinksWrapper>
        //     </ToolbarWrapper>
        // </AppBar>

        <TopbarWrapper>
            <AppBar position="fixed" className={`${props.drawerOpened ? "app-bar-drawer-opened" : "app-bar-drawer-closed"} ${props.isMobileView && "app-bar-drawer-closed"} app-bar`}>
                <ToolbarWrapper>
                    <Typography variant="h6" className="topbar-header" noWrap>
                        {currentRoute && currentRoute[0] && currentRoute[0].title ? currentRoute[0].title : props.location.state && props.location.state.title ? props.location.state.title : ''}
                    </Typography>

                    <LinksWrapper className="flex">

                        {/* <Button color="inherit" onClick={() => navigateTo(URL_DASHBOARD)}>Dashboard</Button> */}
                        {/* <Button color="inherit" onClick={() => navigateTo(URL_USER)}>User</Button> */}

                    </LinksWrapper>

                </ToolbarWrapper>
            </AppBar>
        </TopbarWrapper>
    );
}

const mapReduxStateToProps = (state) => ({
    drawerOpened: state.General.drawerOpened,
    isMobileView: state.General.isMobileView
});

const mapDispatchToProps = (dispatch) => ({
    openDrawer: (payload) => dispatch(openDrawer(payload)),
    closeDrawer: (payload) => dispatch(closeDrawer(payload)),
    logOutUser: (payload) => dispatch(logOutUser(payload)),
    mobileViewChange: (payload) => dispatch(mobileViewChange(payload))
})
export default connect(mapReduxStateToProps, mapDispatchToProps)(withRouter(Topbar));