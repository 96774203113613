import { ACTION_TYPES } from './Actions';

const initState = {
    isLoggedIn: localStorage.getItem('isLoggedIn') ? (localStorage.getItem('isLoggedIn') === "true") : false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '',
    logo: localStorage.getItem('logo') ? localStorage.getItem('logo') : ''
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_USER:
            return {
                ...state,
                isLoggedIn: true
            };

        case ACTION_TYPES.LOGOUT_USER:
            return {
                ...state,
                isLoggedIn: false
            };
        case ACTION_TYPES.SAVE_USER:
            return {
                ...state,
                user: action
            };
        case ACTION_TYPES.SAVE_LOGO:
            return {
                ...state,
                logo: action.data
            }
        default:
            return state;
    }

}

export default Reducer;