export const ACTION_TYPES = {
    SAVE_CATEGORY: "SAVE_CATEGORY",
}

export const saveCategory = (data) => {
    return {
        type: ACTION_TYPES.SAVE_CATEGORY,
        data
    }
}
