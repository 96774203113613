
import { ACTION_TYPES } from './Actions';


const initState = {
    toast: {
        enable: false,
        message: ""
    },
    drawerOpened: true,
    isMobileView: false
}

const Reducer = (state = initState, action) => {
    switch (action.type) {

        case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
            return {
                ...state,
                toast: {
                    enable: true,
                    message: action.message
                }
            };

        case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
            return {
                ...state,
                toast: {
                    enable: false,
                    message: ''
                }
            };

        case ACTION_TYPES.DRAWER_OPEN:
            return {
                ...state,
                drawerOpened: true
            };

        case ACTION_TYPES.DRAWER_CLOSE:
            return {
                ...state,
                drawerOpened: false
            };
        case ACTION_TYPES.MOBILE_VIEW: 
            return {
                ...state,
                isMobileView: action.payload
            }
        default:
            return state;
    }

}

export default Reducer;