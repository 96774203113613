export const ACTION_TYPES = {
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    SAVE_USER: "SAVE_USER",
    SAVE_LOGO: "SAVE_LOGO"
}

export const loginUser = (data) => {
    // SET YOUR LOGIN INFO HERE
    localStorage.setItem('isLoggedIn', true)
    return {
        type: ACTION_TYPES.LOGIN_USER,
        ...data
    }
}

export const logOutUser = () => {
    localStorage.clear();
    return {
        type: ACTION_TYPES.LOGOUT_USER,
    }
}

export const saveUser = (data) => {
    localStorage.setItem('user', JSON.stringify(data))
    return {
        type: ACTION_TYPES.SAVE_USER,
        ...data
    }
}

export const saveLogo = (data) => {
    return {
        type: ACTION_TYPES.SAVE_LOGO,
        data
    }
}