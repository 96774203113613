export const URL_DASHBOARD  = "/dashboard";
export const URL_USER       = "/user";
export const URL_LOGIN      = "/login";
export const URL_SIGNUP     = "/signup"

export const APIS_END_POINTS = {
    //user
    USER_SIGN_UP: "auth/signup",//company signup
    USER_SIGN_IN: "auth/login",//company sign in

    USER_ROLES: "auth/user-roles", //GET role list

    USER_PROFILE: "stable/common/get-profile", //GET user profile

    GET_EVENTS: "stable/webCalendar/list", //POST 
    GET_TRAINERS_LIST: "stable/webCalendar/list-all-trainers", //POST
    
}