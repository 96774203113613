import styled from "styled-components";
import { COLORS, FONTS } from "Styles/Constants";

export const LoginWrapper = styled.div`
    width           : 448px;
    color           : ${COLORS.SECONDARY};

    .header {
        font-family         : ${FONTS.PRIMARY_BOLD};
        flex-direction      : column;

        img {
            margin      : auto;
            margin-top  : 40px;
            width       : 180px;
            height      : 180px;
        }

        .title {
            margin          : 40px auto;
            font-size       : 30px;
        }
    }

    label {
        font-size: 17px !important;
    }
    input {
        font-size: 16px !important;
    }

    .footer {
        flex-direction      : column;
        text-align          : center;

        a {
            color                   : ${COLORS.SECONDARY};
            text-decoration         : none;
            font-size               : 15px;
            font-family             : ${FONTS.PRIMARY_MEDIUM};

            :hover {
                text-decoration         : underline;
            }
        }

        .f-password {
            margin          : 13px auto;
        }

        .login-btn {
            width           : 264px;
            height          : 40px;
            margin          : 12px auto;
        }

        .sign-up {
            margin          : 12px auto;
        }

        .facebook {
            margin              : 70px auto 25px;

            .facebook-text {
                margin-right        : 10px;
                font-size           : 15px;
                font-family         : ${FONTS.PRIMARY_MEDIUM};
            }

            .icon {
                background-color        : #385C8E;
                width                   : 40px;
                height                  : 40px;
                margin                  : auto;
                border-radius           : 50%;
            }
        }
    }
`;


