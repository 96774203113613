import React from "react";
import { TextField } from "@material-ui/core"
import { InputWrapper } from "./Common.style"

export function InputWithLabel(props) {
    let { label, ...rest } = props
    return (
        <InputWrapper className="input-label-wrapper">
            <label className="label">{label}</label>
            <TextField
                {...rest}
            />
            <div className="error elipsis">{props.validated}</div>
        </InputWrapper>
    )
}

