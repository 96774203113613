import { FILTER_CATEGORY } from 'Components/Dashboard/StableCalendar/Categories';
import { ACTION_TYPES } from './Actions';

const initState = {
    categories: FILTER_CATEGORY[0].value
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SAVE_CATEGORY:
            return {
                ...state,
                categories: action.data
            };
        default:
            return state;
    }

}

export default Reducer;